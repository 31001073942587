import mixin from 'public/src/pages/components/product/item_v2/js/mixin.js'
import middleware from 'public/src/pages/goods_detail/components/Recommend/middleware.js'

export default {
  mixins: [mixin],
  data() {
    return {
      itemLanguage: {},
    }
  },
  created () {
    this.itemLanguage = middleware.goodslanguage
  },
  methods: {
    // TODO: 确认下是否在这做预先处理
    async initProductList(params) {
      if (params) {
        const { products = [], itemConfig = {}, field = {}, rowNum = 2, pageKey = '', caller = '', subPageKey, newPreHandle } = params
        const requestMenuList = []
        for (const key in field) {
          if (field[key]) requestMenuList.push(key)
        }
        await middleware.getGoodsInfo({
          items: products,
          requestMenuList,
          rowNum,
          pageKey,
          itemConfig,
          caller,
          subPageKey,
          newPreHandle
        })
      }
    },
  },
}
