import emarsys from './emarsys'
import own from './own'
import middleware from './middleware'
import { loadImg, clipImage } from 'public/src/pages/common/search_words/components/clipImage/utils.js'
import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail/utils/productDetail.js'

class FetchData {
  async getEmarsysData (_params) {
    // Emarsys推荐场景需要支持获取不同橱窗的推荐数据
    const { multiParams = [], withAtomic } = _params
    const params = { ..._params, multiParams: undefined }

    const extendAtomicFiles = params.itemConfig?.extendAtomicFiles || []
    const requestMenuList = ['mallInfo', 'prices', 'stock', 'vipDiscountPrices', 'baseInfo', 'locateLabels', ...extendAtomicFiles]
    // if (params.rowNum == 2) requestMenuList.push('beltLabel') 
    if (params.rowNum == 2 || multiParams.length && multiParams.some(item => item.rowNum == 2)) requestMenuList.push('beltLabel')

    try {
      const productInfo = await emarsys().getData(params)
      // emarsys 推荐先去请求mall信息，否则后续的价格等信息将无法获取
      // if (productInfo.products.length > 0) productInfo.products = await middleware.filterGoods(productInfo.products)

      // if (!productInfo?.products?.length) return productInfo
      const _reqParams = multiParams.length ? multiParams : [params]

      const { mallInfoForSite = [] } = withAtomic ? await getSiteMallInfo() : {}
      productInfo.multiProducts = (await Promise.all(_reqParams.map(async (item) => {

        if (withAtomic) {
          // 允许读取外部原子item?.data?.atomFields
          const fields = item.data?.atomFields ? item.data?.atomFields : getAtomicRequestFileds(item.itemConfig, requestMenuList, true)
          // 深拷贝
          const goods = JSON.parse(JSON.stringify(productInfo.products))
          const opt = {
            sceneKey: item.rowNum == 2 ? 'TWO_IN_A_ROW' : 'THREE_IN_A_ROW', 
            ...(item.data?.atomScene || {}),
            goods,
            fields,
            mallInfo: mallInfoForSite,
            cccParams: item.data.atomCccParams || {},
            abtInfo: item.data.abtInfoV3 || {},
          }
          await itemSer.getAtomicInfoNew(opt)

          // 新商品卡片的场景
          await getDetailRecPreTreat({
            products: goods,
            itemConfig: item.itemConfig
          })

          return goods
        } else {
          const list = await middleware.getGoodsInfo({
            items: productInfo.products,
            requestMenuList,
            rowNum: item.rowNum || 2,
            pageKey: item.pageKey || '',
            itemConfig: item.itemConfig || {},
          })
          return middleware.filterGoods(list)
        }
      }))) || []
      productInfo.products = productInfo.multiProducts[0] || []

      return productInfo
    } catch (e) {
      console.error('getEmarsysData error', e)
      return {
        error: 1,
        reason: '请求错误或者超时',
        products: [],
        multiProducts: [],
        isFaultTolerant: 1,
        dataType: 'emarsys'
      }
    }
  }
  async getOwnData (_params) {
    const multiParams = this._formatMultiParamsBeforeFetch(_params)
    const _reqParams = multiParams.length ? multiParams : [_params]
    try {
      const _results = await Promise.all(_reqParams.map(async params => {
        const extendAtomicFiles = params.itemConfig?.extendAtomicFiles || []
        let requestMenuList = ['prices', 'vipDiscountPrices', 'stock', 'locateLabels', ...extendAtomicFiles]
        if (params.rowNum == 2) requestMenuList.push('beltLabel')

        const rowNum = params.rowNum || 2
        const subPageKey = rowNum == 2 ? 'other_recommend_2pic' : 'other_recommend_3pic'
        if (params.withAtomic) {
          // 允许读取外部原子params?.data?.atomFields
          const fields = params?.data?.atomFields ? params?.data?.atomFields : getAtomicRequestFileds(params.itemConfig, requestMenuList, true)
          params.data.atomFields = fields
          params.data.atomScene = {
            sceneKey: rowNum == 2 ? 'TWO_IN_A_ROW' : 'THREE_IN_A_ROW', 
            pageKey: params.pageKey || '',
            subPageKey,
          }
          // 请求推荐带上原子信息请求
          const _productInfo = await own.fetchData(params)
          // 处理其他信息但是不发起原子请求
          _productInfo.products = await middleware.getGoodsInfo({ 
            items: _productInfo.products, 
            requestMenuList: [],
            rowNum,
            pageKey: params.pageKey || '',
            subPageKey,
            itemConfig: params.itemConfig || {},
            fields,
            unFetchAtomic: true
          })
          return _productInfo
        } else {
          const _productInfo = await own.fetchData(params)
          _productInfo.products = await middleware.getGoodsInfo({ 
            items: _productInfo.products, 
            requestMenuList,
            rowNum,
            pageKey: params.pageKey || '',
            subPageKey,
            itemConfig: params.itemConfig || {},
          })
          return _productInfo
        }

      }))

      const productInfo = _results?.[0]
      productInfo.multiProducts = _results?.map?.(item => item.products)

      return productInfo

    } catch (e) {
      console.error(e)
      return {
        error: 1,
        reason: '请求错误或者超时',
        products: [],
        multiProducts: [],
        isFaultTolerant: 1,
        dataType: _params.dataType || 'own'
      }
    }
  }
  // async getOwnData (params) {
  //   let productInfo = null
  //   const extendAtomicFiles = params.itemConfig?.extendAtomicFiles || []
  //   let requestMenuList = ['prices', 'vipDiscountPrices', 'stock', 'locateLabels', ...extendAtomicFiles]
  //   if (params.rowNum == 2) requestMenuList.push('beltLabel')
  //   try {
  //     const rowNum = params.rowNum || 2
  //     const subPageKey = rowNum == 2 ? 'other_recommend_2pic' : 'other_recommend_3pic'
  //     if (params.withAtomic) {
  //       const fields = getAtomicRequestFileds(params.itemConfig, requestMenuList, true)
  //       params.data.atomFields = fields
  //       params.data.atomScene = {
  //         sceneKey: rowNum == 2 ? 'TWO_IN_A_ROW' : 'THREE_IN_A_ROW', 
  //         pageKey: params.pageKey || '',
  //         subPageKey,
  //       }
  //       // 请求推荐带上原子信息请求
  //       productInfo = await own.fetchData(params)
  //       // 处理其他信息但是不发起原子请求
  //       productInfo.products = await middleware.getGoodsInfo({ 
  //         items: productInfo.products, 
  //         requestMenuList: [],
  //         rowNum,
  //         pageKey: params.pageKey || '',
  //         subPageKey,
  //         itemConfig: params.itemConfig || {},
  //         fields,
  //         unFetchAtomic: true
  //       })
  //     } else {
  //       productInfo = await own.fetchData(params)
  //       productInfo.products = await middleware.getGoodsInfo({ 
  //         items: productInfo.products, 
  //         requestMenuList,
  //         rowNum,
  //         pageKey: params.pageKey || '',
  //         subPageKey,
  //         itemConfig: params.itemConfig || {},
  //       })
  //     }

  //   } catch (e) {
  //     console.error(e)
  //     productInfo = {
  //       error: 1,
  //       reason: '请求错误或者超时',
  //       products: [],
  //       isFaultTolerant: 1,
  //       dataType: params.dataType || 'own'
  //     }
  //   }
  //   return productInfo
  // }
  async fetchOwnDataForGroup (params) {
    let productInfo = null
    let requestMenuList = ['prices']
    try {
      productInfo = await own.fetchDataForGroup(params)

      productInfo.products = await middleware.getGoodsInfo({ 
        items: productInfo.products, 
        requestMenuList,
        rowNum: params.rowNum || 2,
        pageKey: params.pageKey || '',
        itemConfig: params.itemConfig || {},
      })
    } catch (e) {
      productInfo = {
        error: 1,
        reason: '请求错误或者超时',
        products: [],
        isFaultTolerant: 1,
        dataType: params.dataType || 'own'
      }
    }
    return productInfo
  }
  async getProductByImg (params) {
    let originImg = params.data?.image_b64 ||  params.data?.image
    if (!originImg) return console.log('miss image data')

    let productInfo = null
    // let requestMenuList = [
    //   ...new Set([
    //     'prices', 'plusSize'
    //   ].concat(params.listExtField && params.listExtField.length > 0 ? params.listExtField : []))
    // ]
    try {
      // 图片裁剪处理
      const dealImg = async () => {
        const { mw, mh, image } = await loadImg(productInfo.image)
        productInfo.productGroup && productInfo.productGroup.forEach(async (item) => {
          let leftBeginX = item.b0[0] * mw
          let leftBeginY = item.b0[1] * mh
          let clipWidth = item.b1[0] * mw - leftBeginX
          let clipHeight = item.b1[1] * mh - leftBeginY
          const clipInfo = {
            x: leftBeginX,
            y: leftBeginY,
            width: clipWidth,
            height: clipHeight,
          }
          item['point'] = clipInfo
          // eslint-disable-next-line require-atomic-updates
          item['image'] = await clipImage(image, clipInfo)
        })
      }
      productInfo = await own.fetchDataByImg(params)
      let promiseQueue = [
        dealImg(),
      ]

      await Promise.all(promiseQueue)
    } catch (e) {
      console.log(e)
      // eslint-disable-next-line require-atomic-updates
      productInfo = {
        error: 1,
        reason: '请求错误或者超时',
        productGroup: [],
        products: [],
        isFaultTolerant: 1,
        dataType: params.dataType || 'own',
      }
    }
    return productInfo
  }
  // 将params中的参数与multiParams中的参数进行合并
  _formatMultiParamsBeforeFetch(_params = {}) {
    const { multiParams = [] } = _params
    if (!multiParams.length ) return _params
    const params = { ..._params, multiParams: undefined }

    return multiParams.map(item => {
      const data = { ...(_params.data || {}), ...(item.data || {}) }
      return { ...params, ...item, data }
    })
  }
}

export {
  FetchData
}
