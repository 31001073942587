// import request from '../../pages/product_app/request'
const { lang } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
import { transformImg }  from '@shein/common-function'

import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { getLanguage } from 'public/src/services/api/index.js'
import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail/utils/productDetail.js'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'

class Middleware {
  constructor() {
    this.goodslanguage = {}
    if (typeof window !== 'undefined') {
      getLanguage('product_item').then(result => {
        this.goodslanguage = result?.language || {}
      })
    }
  }
  // 转换数据格式
  adapter ({ type = '', data = [] } = {}) {
    if (type == 'emarsys') {
      data = data.map(item => {
        if (lang == 'mx') {
          item.goods_url = item['c_link_es'] && item['c_link_es'].replace('es.shein.com', 'mx.shein.com')
          item.goods_name = item['c_title_es']
        } else if (lang == 'nl') {
          item.goods_url = item['c_link_de'] && item['c_link_de'].replace('de.shein.com', 'nl.shein.com')
          item.goods_name = item['c_title_nl'] || item['c_title_de']
        } else if(item[`c_link_${lang}`]){
          item.goods_url = item[`c_link_${lang}`]
          item.goods_name = item[`c_title_${lang}`]
        } else {
          item.goods_url = item[`c_link_www`]
          item.goods_name = item[`c_title_www`]
        }
        return {
          productRelationID: item.c_relation_id,
          goods_id: item.id,
          cat_id: item.c_catid,
          goods_url_name: item[`c_title_www`] || item.title,
          salePrice: {},
          retailPrice: {},
          unit_discount: 0,
          goods_name: item.goods_name,
          goods_img: transformImg({ img: item.image }),
          goods_sn: item.c_sku,
          relatedColor: [],
          dataSource: 'emarsys'
        }
      })
    }
    if (type == 'own') {
      data = data.map(item => ({
        productRelationID: item.spu,
        goods_img: item.goodsImg,
        goods_id: item.goodsId,
        goods_sn: item.goodsSn,
        cat_id: item.catId,
        goods_url: getGoodsUrl(item.goodsUrlName || item.goodsName, item.goodsId, item.catId),
        goods_name: item.goodsName,
        goods_url_name: item.goodsName,
        relatedColor: [],
        salePrice: item.salePrice || {},
        retailPrice: item.retailPrice || {},
        unit_discount: item.unit_discount || 0,
        dataSource: 'own'
      }))
    }
    return data
  }
  // 过滤掉当前商品
  filterCurrentItem (curr, items) {
    return items.filter(item => item.goods_id != curr.goods_id)
  }
  // 筛除无货商品 / 下架商品
  filterGoods (recommendList) {
    if (!recommendList || !recommendList.length) return []
    return recommendList.filter((item) => Number(item.stock > 0) && Number(item.is_on_sale) === 1 && Number(item.is_del) === 0)
  }

  /**
   * 请求商品额外的扩展信息
   * @param {*} param0 
   * @returns 
   */
  async getGoodsInfo({ items, requestMenuList = ['prices'], rowNum, pageKey, itemConfig, fields: paramFields, unFetchAtomic = false, caller, subPageKey } = {}) {
    // requestMenuList = ['price', 'color', 'plugSize', 'goodsName', 'soldInfo', 'stock', 'promotionInfo', 'goodLabel']
    if (!items || !items.length) return Promise.resolve(items)

    const isGoodsRec = !!items[0]['goods_id']
    // 类目不请求商品信息
    if (!isGoodsRec) return Promise.resolve(items)
    
    // 商品原子服务
    // 商品的原子请求字段
    const fields = paramFields || getAtomicRequestFileds(itemConfig, requestMenuList, true)
    const goodsItemInfo = new GoodsItemInfo({
      itemLanguage: this.goodslanguage
    })
    const backupSubPageKey = subPageKey || (rowNum == 2 ? 'other_recommend_2pic' : 'other_recommend_3pic')
    const params = {
      goods: items,
      requestFields: unFetchAtomic ? {} : fields,
      pageKey: pageKey || backupSubPageKey,
      subPageKey: backupSubPageKey,
      sceneKey: rowNum == 2 ? 'TWO_IN_A_ROW' : 'THREE_IN_A_ROW', 
      itemConfig,
      caller: caller || 'Recommend'
    }

    // isNewItem兼容旧参数
    const isNewCard = itemConfig.isNewItem || itemConfig._isNew

    // 旧商品卡片的场景
    if (!isNewCard) {
      await goodsItemInfo.getProductInfo(params)
      // 如果有请求多颜色
      // 小多色块图标不需要请求色块原子信息
      if (fields.relatedProducts && !(itemConfig.showMultiColorIcon || itemConfig.colorSetPresentedType)) {
        await goodsItemInfo.getRelateColorProductInfo({ goods: items }, fields)
      }

      return Promise.resolve(items)
    } else {
      // 新商品卡片的场景
      const list = await getDetailRecPreTreat({
        products: items,
        itemConfig: itemConfig
      })
      return Promise.resolve(list)
    }
  }
}

export default new Middleware()
