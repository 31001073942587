import { abtservice } from 'public/src/services/abt'
const { WEB_CLIENT } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
/** 
 * emarsys map config
*/
const logicMap = new Map()
logicMap.set('emarsys_personal', 'PERSONAL')
logicMap.set('emarsys_related', 'RELATED')
logicMap.set('emarsys_emptycart', 'CART')
logicMap.set('emarsys_cart', 'CART')
logicMap.set('emarsys_search', 'SEARCH')
logicMap.set('emarsys_home', 'HOME')

const openTheEmarsysAcountLangMap = {
  'shein': [
    'en', 'fr', 'de', 'es', 'uk', 'ar', 'ar-en',
    'au', 'in', 'us', 'ca', 'cafr', 'il', 'ilen', 'it', 
    'mx', 'nl', 'tw', 'ru', 'se', 'sesv', 
    'eur', 'eurfr', 'eurnl', 'eurpt-pt',
    'br'
  ],
  'romwe': ['en', 'us', 'fr', 'es', 'uk']
}

const openTheEmarsysAcountLang = openTheEmarsysAcountLangMap[WEB_CLIENT]

/**
 * 是否要进行earmsys基础数据上传的限制
 */
const pushCommandFlag = async () => {
  // eslint-disable-next-line @shein-aidc/abt/abt
  const { ProductDetailYouMayAlsoLike } = await abtservice.getUserAbtResult({ posKeys: 'ProductDetailYouMayAlsoLike', })
  if (ProductDetailYouMayAlsoLike) {
    if (ProductDetailYouMayAlsoLike.version == 'v4') {
      // 2. 如果配置推荐是自有推荐 & abt控制不上传
      return !(ProductDetailYouMayAlsoLike.param)
    } else {
      // 1. 没有配置参数默认原来逻辑上报
      const detailAbtPde = !ProductDetailYouMayAlsoLike.param || /^is_pde=3/.test(ProductDetailYouMayAlsoLike.param) || ProductDetailYouMayAlsoLike.param == 'none'
      // 没有配置参数 || 参数为pde推荐 || 参数配置为none
      // 2. 如果配置推荐是自有推荐 & abt控制不上传
      return !(detailAbtPde)
    }
  }

  return true
}

export { logicMap, openTheEmarsysAcountLang, pushCommandFlag }
